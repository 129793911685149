<template>
  <!-- <a-select
    v-model:value="selected"
    allowClear
    placeholder="请选择"
    @change="onChange"
    labelInValue
    style="width: 120px"
    :options="items"
  ></a-select> -->
  <a-select
    v-model:value="selected"
    show-search
    allowClear
    labelInValue
    placeholder="请选择"
    style="width: 120px"
    :default-active-first-option="false"
    :show-arrow="false"
    :filter-option="false"
    :not-found-content="null"
    :options="items"
    @change="onChange"
  ></a-select>
</template>
<script>
  import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    watchEffect,
    computed,
  } from 'vue'
  import { API, requests } from '@/api/apis'
  import { useStore } from 'vuex'

  export default defineComponent({
    props: {
      rolesname: String,
      index: Number,
      value: Number,
      title: String,
      departments_id: Number,
    },
    emits: ['updateData', 'update:value', 'update:title'],
    setup(props, ctx) {
      const loadstatus = ref(true)
      const store = useStore()
      const worlflowlist = computed(() => store.state.auth.worlflowlist)
      const worlflownumber = computed(() => store.state.auth.wonumber)
      const items = ref([])
      const selected = ref(
        props.value ? { value: props.value, label: props.title } : undefined
      )
      onMounted(() => {
        store.dispatch('auth/MyWorlflowNumFun', worlflownumber.value + 1)
        if (worlflownumber.value) {
          setTimeout(() => {
            if (worlflowlist.value.length > 0) {
              worlflowlist.value.forEach((res) => {
                if (res.title == props.rolesname) {
                  items.value = res.list
                  loadstatus.value = false
                  return loadstatus.value
                }
                return loadstatus.value
              })
            }
            if (loadstatus.value) {
              doRequest()
            }
          }, (worlflownumber.value + 1) * 700)
        } else {
          doRequest()
        }
      })
      const onChange = (val) => {
        if (val) {
          ctx.emit('update:value', val.value)
          ctx.emit('update:title', val.label)
          ctx.emit('updateData', {
            key: val.value,
            label: val.label,
            idx: props.index,
          })
        } else {
          ctx.emit('update:value', undefined)
          ctx.emit('update:title', undefined)
          ctx.emit('updateData', {
            key: undefined,
            label: undefined,
            idx: undefined,
          })
        }
      }
      const doRequest = () => {
        if (!loadstatus.value) return false
        requests({
          url: API.roles.SelectForWorkflow,
          data: {
            roles_name: props.rolesname,
            departments_id: props.departments_id,
          },
        })
          .then((res) => {
            const { data } = res
            if (res.data) {
              data[0].admins.forEach((json) => {
                json.label = json.realname
                json.value = json.id
              })
              items.value = data[0].admins
              worlflowlist.value.push({
                title: props.rolesname,
                list: data[0].admins,
              })
              store.dispatch('auth/myWorlflowFun', worlflowlist.value)
            }
          })
          .catch((err) => {
            console.log(err, '数据')
          })
      }
      return {
        items,
        selected,
        onChange,
      }
    },
  })
</script>
